import React from 'react';
import Layout from '../components/Layout/layout';
import { graphql } from 'gatsby';

import Seo from '../components/seo';

import { MainScreenFirst, CrumbsNav } from '../components/Pages/About';

import { contactsData } from '../db/contactsData';
import { foodPhotographersStatic } from '../db/foodPhotographersStatic';
import BeautyPhotographers from '../components/Pages/BeautyPhotographers/BeautyPhotographers';

const BeautyPhotographersPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title={data.strapiFoodPhotographersPage.seo_title}  
        description={data.strapiFoodPhotographersPage.seo_description}
      />

      <MainScreenFirst
        title={data.strapiFoodPhotographersPage.main.title}
        socials={contactsData.socials}
        showreel={data.strapiFoodPhotographersPage.main.showreel}
        video={data.strapiFoodPhotographersPage.main.video_cover}
        poster={data.strapiFoodPhotographersPage.main.poster}
      />

      <BeautyPhotographers
        items={data.strapiFoodPhotographersPage.photographers}
        tickers={foodPhotographersStatic.tickers}
      />

      <CrumbsNav dataCrumbs={foodPhotographersStatic.crumbsNav} />
    </Layout>
  );
};

export default BeautyPhotographersPage;

export const query = graphql`
 query {
  strapiFoodPhotographersPage {
      seo_title
      seo_description
      main {
        title
        showreel
        video_cover {
          url
        }
        poster {
          url
        }
      }
      photographers {
        title
        link
        cover {
          cover_image {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
`