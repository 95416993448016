export const foodPhotographersStatic = {
  crumbsNav: [
    { id: 1, title: 'Home', link: '/' },
    { id: 2, title: 'Food Photographers', link: '/food-photographers/' },
  ],
  tickers: [
    'Food',
    'Photographers',
    'Portfolio',
    'Food',
    'Photographers',
    'Portfolio',
  ],
};
